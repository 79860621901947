<template>
  <main id="portafolio" class="p-0">
    <ProjectList :portfolio="this.portfolio"></ProjectList>
  </main>
</template>

<script>
import ProjectList from "../components/layout/ProjectList.vue";

export default {
  components: {
    ProjectList,
  },
  computed: {
    portfolio() {
      return this.$store.getters["portfolio/portfolio"];
    },
  },
  created() {
    document.title = this.$t("nav.portfolio") + " | Piero del Valle";
  },
};
</script>

<style>
</style>